/* 
Primary color: #A37E2C
Secondary color: #EDC967
Background color: #121212
Card background color: #202020
Main text color: #d5d5d5
*/

:root {
  --primary: #A37E2C;
  --secondary: #EDC967;
  --background: #121212;
  --card-background: #202020;
  --text: #d5d5d5;
}

/*Titles*/
h1 {
  color: #d5d5d5;
  line-height: 2em;

}

h2 {
  color: #d5d5d5;


}

h4 {
  color: #d5d5d5;


}

h5 {
  color: #d5d5d5;
  margin-right: 10px;
  padding: 0px;
  flex-grow: 1;
  margin-bottom: 5px;
  margin-top: 5px;

}


/* Body */
body {
  color: #d5d5d5;
  background-color: var(--background);
  text-align: center;
  font-family: 'inter', sans-serif;
  line-height: 2em;
  padding-bottom: 60px;
}

/* Cards */
/* if the user is on a mobile device, the card will be smaller of the screen width */
@media screen and (max-width: 600px) {
  .card {
    margin: auto;
    margin-top: 50px;
    padding: 30px;
    max-width: 400px;
    border-radius: 10px;
    width: fit-content;
    background: #202020;
    box-shadow: 34px 34px 68px #070707,
      -34px -34px 68px #141414;
    align-content: center;
    margin-bottom: 20px;
  }
}

/* if the user is on a desktop, the card will be larger*/
@media screen and (min-width: 600px) {
  .card {
    margin: auto;
    margin-top: 50px;
    padding: 30px;
    border-radius: 10px;
    width: "fit-content";
    max-width: 600px;
    background: #202020;
    box-shadow: 34px 34px 68px #070707,
      -34px -34px 68px #141414;
    align-content: center;
    margin-bottom: 20px;

  }
}

.small-card {
  margin: auto;
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  width: 350px;
  background: #272727;
  box-shadow: 13px 13px 26px #131313,
    -13px -13px 26px #1a1a1a;
  align-content: center;
  display: flex;

}

/* Button */
.button {
  font-weight: bold;
  margin: auto;
  margin-top: 15px;
  align-items: center;
  background-image: linear-gradient(144deg, var(--primary), var(--secondary));
  border: 0;
  border-radius: 20px;
  box-sizing: border-box;
  color: var(--background);
  display: flex;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 15px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all .3s;
  box-shadow: 4px 4px 10px #070707;
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

.button span {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.button:hover span {
  background: none;
}

.button:active {
  transform: scale(0.9);
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* Text input */

.form__group {
  position: relative;
  padding: 20px 0 0;
  width: 100%;
  max-width: 250px;
  margin-top: auto;
  margin-inline: 10px;
  display: inline-block;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 14px;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  margin: auto;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown~.form__label {
  font-size: 14px;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 14px;
  color: #9b9b9b;
  pointer-events: none;
  font-weight: 700;
}

.form__field:focus {
  padding-bottom: 6px;
  border-width: 3px;
  border-image: linear-gradient(to right, var(--primary), var(--secondary));
  border-image-slice: 1;
}

.form__field:focus~.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 14px;
  color: var(--primary);
  font-weight: 700;
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}


.offscreen {
  display: none;
}


.istructions {
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  max-width: 190px;
  min-width: 80px;
  border-radius: 10px;
  width: fit-content;
  background: #3f3f3f00;
  font-size: x-small;
  line-height: 1.2em;
  border: 2px solid var(--primary);
}


a {
  color: var(--secondary);
}


.text-small {
  font-size: smaller;
}


.qr-canvas {

  margin-bottom: 15px;
  border-radius: 20px;
  line-height: 0;
  overflow: hidden;
  position: relative;
  width: fit-content;
}



/* Switch */
.switch input {
  display: none;
  text-align: justify;
}

.switch {
  width: 60px;
  height: 30px;
  position: relative;
  vertical-align: middle;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #777, 0 0 4px #777;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.2s;
  text-align: justify;
}

.slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #777;
  border-radius: 30px;
  transform: translateX(-30px);
  /*translateX(-(w-h))*/
  transition: 0.2s;
}

input:checked+.slider:before {
  transform: translateX(30px);
  /*translateX(w-h)*/
  background-color: var(--primary);
}

input:checked+.slider {
  box-shadow: 0 0 0 2px var(--primary), 0 0 4px var(--primary);

}



/* Navbar */

/* if user is on mobile, make the navbar fixed to the bottom */
@media only screen and (max-width: 600px) {

  .navbar {
    background-color: var(--background);
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .navbar .Link {

    display: inline-block;
    color: var(--primary);
    text-align: center;
    padding: 10px 0px;
    padding-bottom: 5px;
    text-decoration: none;
    font-size: 17px;
    width: 25%;
  }

  /* Change the color of links on hover */
  .navbar .Link:hover {
    background-color: rgb(90, 90, 90);
    color: black;
  }

  /* Add a color to the active/current link */
  .navbar .Link.active {
    background-image: linear-gradient(144deg, var(--primary), var(--secondary));
    color: var(--background);
  }

}

/* if user is on desktop, make the navbar fixed to the left */
@media only screen and (min-width: 600px) {

  .navbar {
    background-color: var(--background);
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 25%;
  }

  .navbar .Link {

    display: block;
    color: #c4c4c4;
    text-align: center;
    padding: 0px 15px;
    text-decoration: none;
    font-size: 17px;
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    line-height: 0.5;
  }

  /* Change the color of links on hover */
  .navbar .Link:hover {
    background-color: rgb(29, 29, 29);
    color: gray;
  }

  /* Add a color to the active/current link */
  .navbar .Link.active {
    background-image: linear-gradient(144deg, var(--primary), var(--secondary));
    color: var(--background);
  }

}

/* Loader */
.spinner {
  position: relative;
  margin: auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.spinner::before,
.spinner:after {
  content: "";
  position: absolute;
  border-radius: inherit;
}

.spinner:before {
  width: 100%;
  height: 100%;
  left: 0;
  background-image: linear-gradient(0deg, var(--primary) 0%, var(--secondary) 100%);
  animation: spin .5s infinite linear;
}

.spinner:after {
  width: 80%;
  height: 80%;
  background-color: #212121;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-wave {
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 50px auto;
}

.loading-bar {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background-color: var(--secondary);
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }

  50% {
    height: 50px;
  }

  100% {
    height: 10px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-item {
  background: #272727;
  box-shadow: 13px 13px 26px #131313,
    -13px -13px 26px #1a1a1a;
  align-content: center;
  padding: 10px;
  font-size: 30px;
  text-align: center;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

/* alert */
.alert {
  padding: 20px;
  color: white;
  opacity: 1;
  transition: opacity 0.6s;
  margin-bottom: 15px;
  margin-top: 10px;
  border-radius: 10px;
}

.alert.error {
  background-color: #f44336;
}

.alert.success {
  background-color: #4CAF50;
}

.alert.info {
  background-color: #2196F3;
}

.alert.warning {
  background-color: #ff9800;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

/* alert end */

/*table for mobile*/
@media only screen and (max-width: 600px) {
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 20px 0px;
  }

  table,
  th,
  td {
    border: 1px solid var(--primary);
    padding: 3px;
    text-align: center;
    font-size: 12px;
  }

  th {
    background-color: var(--primary);
    color: var(--text);
  }

}

/*table for desktop, dark ui modern like stripe*/
@media only screen and (min-width: 600px) {


  table {
    width: 100%;
    background-color: #202020;
    border-collapse: collapse;
    border-width: 0px;
    border-color: #7EA8F8;
    border-style: solid;
    color: #D5D5D5;
    font-size: small;
  }

  table td,
  table th {
    border-width: 0px;
    padding: 15px;
  }

  table tbody tr:hover {
    background-color: #333;
    box-shadow: 0px 0px 10px #0e0e0e;
    transition: 0.2s;
    transform: scale(1.02);
  }

  table thead {
    color: grey;
    border-bottom: solid #777 1px;
  }


}


/*table end*/
/*progress bar*/
.progress {
  width: 100%;
  background-color: #ddd;
  border-radius: 10px;
  align-items: center;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: var(--primary);
  transition: width 0.2s ease-in;
}

/*progress bar end*/

/*glassmorphism card*/
.glass {
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}